<template>
  <v-container>
    <v-card elevation="0" :loading="!viewReady">
      <v-card-text>
        <v-row>
          <v-col class="d-flex justify-center">
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='1DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='1A4'" width="230mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='2DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='2A4'" width="230mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='3A4'" width="230mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='4DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='6SIMPLE'" width="145mm"
                    max-width="145mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='5DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
<!--                <div v-if="!pdfReady" v-for="(product, index) in formData.productsData" :key="index">
                  <qrcode class="qrcode" :value="getQRCode128(index)" :options="{ size: 78}"
                          tag="img"></qrcode>
                </div>-->
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='5A4'" width="230mm"
                    max-width="230mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--NO EXISTE -->
            <v-card v-if="formData.labelType.id+formData.printType==='7DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ this.formData.labelType.text }}<strong>{{ '(' + this.formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div v-if="!pdfReady" id="svg-container">
                  <qrcode id="qrcode" :value="getQRCodePreCadastro" :options="{ size: 90}"
                          tag="img"></qrcode>
                </div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='8SIMPLE'" width="110mm"
                    max-width="110mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
              <!-- <div v-if="!pdfReady" v-for="(product, index) in formData.productsData" :key="index">
                  <qrcode class="qrcode" :value="getQRCode128(index)" :options="{ size: 78}"
                          tag="img"></qrcode>
                </div>-->
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     :rotate="90"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='9SIMPLE'" width="110mm"
                    max-width="110mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
              <!--<div v-if="!pdfReady">
                  <qrcode class="qrcode" :value="getQRCode128(index)" :options="{ size: 78}"
                          tag="img"></qrcode>
                </div>-->
                <div style="display: none" ref="qrcode"></div>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>

            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='10DUPLO'" width="170mm"
                    max-width="170mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='10A4'" width="230mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card v-if="formData.labelType.id+formData.printType==='11SIMPLE'" width="100mm"
                    max-width="100mm">
              <v-card-title class="headline">
                {{ formData.labelType.text }}<strong>{{ '(' + formData.printType + ')' }} </strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <img style="display: none" id="companylogo"
                     v-if="company.length > 0 && company[0].logo"
                     :src="logocompany"
                ></img>
                <img style="display: none" id="agtracelogo" :src='logoagtrace'></img>
                <div style="display: none" ref="qrcode"></div>
                <img style="display: none" id="lineas" :src="lineas" alt=""/>
                <pdf :src="getPdfAction"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="secondary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2">{{ currentPage }} / {{ pageCount }}</span>
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="secondary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>

                <v-btn small text color="gray" @click="printCancel">
                  {{ $t('general.buttons.cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn small text color="secondary" @click="save('PRINT')">
                  {{ $t('general.buttons.print') }}
                </v-btn>
                <v-btn small text color="secondary" @click="save('DOWNLOAD')">
                  {{ $t('general.buttons.confirm') }}
                </v-btn>

              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import JSPDF from 'jspdf-yworks';
import pdf from 'vue-pdf';
import i18n from '@/plugins/i18n';
import LanguajeService from "../../../services/LanguajeService";
import VueBarcode from 'vue-barcode';
import moment from 'moment';
import * as QRCode from 'easyqrcodejs'
import { v4 as uuidv4 } from 'uuid'
import { convertLatitude, convertLongitude, isValidLatLong } from "../../../../utils/convert-to-DMS-format.ts";
import JsBarcode from "jsbarcode"

export default {
  components: {
    pdf,
    'barcode': VueBarcode
  },
  data: () => ({
    loadingSave: false,
    labelData: [],
    profile: null,
    valid: false,
    lineas: null,
    lineas110: null,

    dialog: false,
    editedIndex: -1,
    refers: {
      packingDate: '',
      expirationDate: '',
      manufacturingDate: ''
    },
    formData: {
      labelType: '',
      printType: '',
      producedBy: '',
      allAddressProduced: '',
      documentValueProduced: '',
      distributedBy: '',
      allAddressDistributed: '',
      documentValueDistributed: '',
      providerCode: '',
      netWeight: '',
      packingDate: null,
      expirationDate: null,
      manufacturingDate: null,
      additionalInformation: '',
      sdi_origin: '',
      sdi_destination: ''
    },
    defaultItem: {},
    showDialog: false,
    title: 'Información',
    dialogText: 'Añada un texto a este dialogo',
    max: 40,
    //Label
    doc: '',
    pdfReady: false,
    viewReady: false,
    dataReady: false,
    routa: '',
    currentPage: 1,
    pageCount: 0,
    trackingLot: "",
    trackingProduct: "",
    logoagtrace: require('@/assets/img/logooriginalagtrace.png'),
    logocompany: require('@/assets/img/maues.jpg'),
    profile: null
  }),

  computed: {
    ...mapGetters({
      getLinea: 'labels/lineas',
      getLinea110: 'labels/lineas110',
      label_data: 'general/label_data',
      pre_records_barcode: 'general/pre_records_barcode',
      company: "company/companyById"
    }),
    getPdfAction() {
      return this.dataReady && this.viewReady ? this.getPdf() : "";
    },
    getCodePLU() {
      return (index) => this.getBarCodePLU(index);
    },
    getQRCodePreCadastro() {
      return this.pre_records_barcode.id;
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    recordSelected: {
      get() {
        return this.$store.getters['records/record'];
      },
      set(val) {
        this.$store.commit('records/SET_RECORD', val)
      }
    },
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
  },

  created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.initialize();
  },
  updated() {
    this.readyView();
  },
  methods: {
    ...mapActions({
      saveLabel: 'labels/saveLabel'
    }),
    translate(key) {
      return LanguajeService.getKey3(key)
    },
    async initialize() {
      if (this.$route.params.id === 'pre_recordsLabel') {
        setTimeout(() => {
          this.formData.labelType = {id: 7, text: i18n.t('menu.prerecords')};
          this.formData.printType = 'DUPLO';
          this.setjsPdfdata(this.formData.labelType.id, this.formData.printType);
          this.routa = '/prerecords';
        }, 500);
      } else {
        this.profile = JSON.parse(localStorage.getItem('profile'));
        if (this.$route.params.id === 'recordsLabel') {
          this.lineas = require('@/assets/img/' + this.getLinea + '.png');
          this.lineas110 = require('@/assets/img/' + this.getLinea110 + '.png');
          // this.formData = Object.assign(this.label_data);
          this.routa = '/records';
        }
        if (this.$route.params.id === 'product') {
          // this.formData = Object.assign({}, JSON.parse(localStorage.getItem('label_data_product')));
          this.routa = '/nomenclators/products';
        }
        this.formData = Object.assign(this.label_data);
        this.setjsPdfdata(this.formData.labelType.id, this.formData.printType);
      }
    },
    setjsPdfdata(labelTypeId, printType) {
      let format = null;
      let orientation = null;
      switch (labelTypeId + printType) {
        case '1DUPLO':
          format = [453.543, 288];
          orientation = 'landscape';
          break;
        case '1A4':
          format = [595, 842];
          orientation = 'portrait';
          break;
        case '2DUPLO':
          format = [453.543, 288];
          orientation = 'landscape';
          break;
        case '2A4':
          format = [595, 842];
          orientation = 'portrait';
          break;
        case '3A4':
          format = [595, 842];
          orientation = 'landscape';
          break;
        case '4DUPLO':
          format = [453.543, 288];
          orientation = 'landscape';
          break;
        case '5DUPLO':
          format = [283.465, 226.772];
          orientation = 'landscape';
          break;
        case '5A4':
          format = [595, 842];
          orientation = 'portrait';
          break;
        case '6SIMPLE':
          format = [411.024, 178.583];
          orientation = 'landscape';
          break;
        case '7DUPLO':
          format = [283.465, 226.772];
          orientation = 'landscape';
          break;
        case '8SIMPLE':
          format = [178.583, 294.803];
          orientation = 'landscape';
          break;
        case '9SIMPLE':
          format = [178.583 ,294.803 ];
          orientation = 'portrait';
          break;
        case '10DUPLO':
          format = [453.543, 288];
          orientation = 'landscape';
          break;
        case '10A4':
          format = [595, 842];
          orientation = 'portrait';
          break;
        case '11SIMPLE':
          format = [283.46, 425.20];
          orientation = 'portrait';
          break;
      }
      this.doc = new JSPDF({
        orientation: orientation,
        unit: 'pt',
        format: format
      });
      this.dataReady = true;
    },
    getQRCode128(index) {
      if (this.$route.params.id === 'product') {
        return '01' + (this.formData.productsData[index].product ? this.formData.productsData[index].product.gtin : '') +
            (!this.formData.productsData[index].unit || !this.formData.productsData[index].unit.gs1AI ? '' : (this.getGS1AIUnit(this.formData.productsData[index].unit.gs1AI, this.formData.productsData[index].netWeight) + this.getCantGS1(this.formData.productsData[index].netWeight))) +
            ('10' + this.formData.productsData[index].lot).toUpperCase();
      } else {
        return this.getBarCode128(null, index);
      }
    },
    getPdf() {
      const lineas = document.querySelector('img#lineas');

      if (this.formData.labelType.id + this.formData.printType === '1DUPLO' || this.formData.labelType.id + this.formData.printType === '2DUPLO') {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let yOffset = 10;
          let yQR = 0;
          let yProduct = 15;
          let dataLabel = 75;
          let provider = 105;
          let netWeight = 118;
          let agtrace = 130;
          let tracking = 132;

          product.code = uuidv4();

          const link = process.env.VUE_APP_BACK_FRONT + '/product_traceability/' + product.code

          let text
          if(this.formData.labelType.id + this.formData.printType === '1DUPLO')
            text = this.getBarCode128(['u'], index)
          else
            text = this.getBarCodePLU(index)

          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
          });

          const imgBase64Data = qrcode._oDrawing._elCanvas.toDataURL("image/png")

          let JsBarcode = require('jsbarcode');

          let canvas = document.createElement("canvas");
          JsBarcode(canvas, text, {
            format: 'CODE128',
            font: 'Arial',
            width:2,
            height:30,
            displayValue: true,
            fontSize: 12
          });
          const barcode = canvas.toDataURL("image/png");

          for (let i = 0; i < 2; i++) {
            // Render the svg Barcode
            const xOffsetBar = this.formData.labelType.id + this.formData.printType === '1DUPLO' ? 50 : 105;
            // Render the img Barcode
            this.doc.addImage(barcode, 'png', xOffsetBar, yOffset);
            // Render the img QRCode
            this.doc.addImage(imgBase64Data, 'png', 340, yQR, 90, 90);
            // Render Product Name
            this.setFontConfig(10, '#232323', 'normal', 'bold');
            this.doc.text(this.getProduct(index), 160, yProduct);
            // Render Label data
            this.getLabelData(5, dataLabel, 8, 8, index);
            // Render provider code
            if (product.providerCode !== '' && product.providerCode !== null) {
              this.writeBoldAndNormalText((i18n.t('records.label.titles.providerCode') + ": " + "**" + product.providerCode + "**"), 270, provider, 10, 10);
            }
            if (product.netWeight !== '' && product.netWeight !== null) {
              this.writeBoldAndNormalText((i18n.t('records.label.titles.netWeight') + ": " + "**" + parseFloat(product.netWeight).toFixed(this.decimalFormat) + ' ' + product.unit.code + "**"), 270, netWeight, 12, 10);
            }
            this.setFontConfig(8, '#232323', 'normal', 'normal');
            this.doc.text('Powered by AgTrace', 445, agtrace, {'angle': 90});

            this.doc.addImage(lineas, 'png', 0, tracking, 453, 12);
            yOffset += 145;
            yQR += 145;
            yProduct += 145;
            dataLabel += 145;
            provider += 145;
            netWeight += 145;
            agtrace += 145;
            tracking += 145;
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '10DUPLO' ) {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let yOffset = 10;
          let yQR = 5;
          let yTop = 15;
          let dataLabel = 87;
          let provider = 105;
          let netWeight = 118;
          let agtrace = 130;
          let tracking = 132;


          product.code = uuidv4();

          const link = process.env.VUE_APP_BACK_FRONT + '/label_traceability/' + product.code

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });
          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
          });
          const imgBase64Data = qrcode._oDrawing._elCanvas.toDataURL("image/png")

          for (let i = 0; i < 2; i++) {
            // Render the img QRCode Product
            this.doc.addImage(qrcodeProduct, 'png', 5, yQR, 70, 70);
            // Render the img QRCode Traceability
            this.doc.addImage(imgBase64Data, 'png', 360, yQR, 70, 70);
            // Render data TOP
            let xTop = 85;
            this.getTopLabelDataGS1v2(xTop,yTop, 10,10,index, 200, 180, 50);
            // Render Label data
            this.getLabelDataGS1v2(5, dataLabel, 10, 10, index, product);

            this.setFontConfig(8, '#232323', 'normal', 'normal');
            this.doc.text('Powered by AgTrace', 445, agtrace, {'angle': 90});

            this.doc.addImage(lineas, 'png', 0, tracking, 453, 12);
            yOffset += 145;
            yQR += 145;
            yTop += 145;
            dataLabel += 145;
            provider += 145;
            netWeight += 145;
            agtrace += 145;
            tracking += 145;
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '1A4' || this.formData.labelType.id + this.formData.printType === '2A4') {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {

          let offset = this.formData.labelType.id + this.formData.printType === '1A4' ? [0, 10] : [70, 10];
          let QR = [240, 6];
          let yProduct = [65, 15];
          let barText = [50, 15];
          let dataLabel = [5, 80];
          let provider = [180, 120];
          let netWeight = [180, 130];
          let agtrace = [295, 148];
          let tracking = [0, 150];

          product.code = uuidv4();

          const link = process.env.VUE_APP_BACK_FRONT + '/product_traceability/' + product.code

          let text
          if(this.formData.labelType.id + this.formData.printType === '1A4')
            text = this.getBarCode128(['u'], index)
          else
            text = this.getBarCodePLU(index)

          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
          });

          const imgBase64Data = qrcode._oDrawing._elCanvas.toDataURL("image/png")

          let JsBarcode = require('jsbarcode');

          let canvas = document.createElement("canvas");
          JsBarcode(canvas, text, {
            format: 'CODE128',
            font: 'Arial',
            width: this.formData.labelType.id + this.formData.printType === '1A4' ? 1.5 : 2,
            marginTop: 0,
            marginRight: 5,
            height:30,
            displayValue: true,
            fontSize: 12
          });
          const barcode = canvas.toDataURL("image/png");

          for (let i = 0; i < 5; i++) {
            offset = this.formData.labelType.id + this.formData.printType === '1A4' ? [20, offset[1]] : [28, offset[1]];
            QR = [230, QR[1]];
            yProduct = [120, yProduct[1]];
            barText = [50, yProduct[1]];
            dataLabel = [5, dataLabel[1]];
            provider = [180, provider[1]];
            netWeight = [180, netWeight[1]];
            agtrace = [295, agtrace[1]];
            tracking = [0, tracking[1]];
            for (let l = 0; l < 2; l++) {
              // Render the svg Barcode
              // Render the img QRCode
              this.doc.addImage(barcode, 'png', offset[0], offset[1]);
              // Render the img QRCode
              this.doc.addImage(imgBase64Data, 'png', QR[0], QR[1], 60, 60);
              // Render Product Name
              this.setFontConfig(9, '#232323', 'normal', 'bold');
              let splitTitle = this.getProduct(index).substring(0, 35);
              this.doc.text(splitTitle, yProduct[0], yProduct[1], 'center');
              // Render Label data
              this.getLabelData(dataLabel[0], dataLabel[1], 6, 6, index);
              // Render provider code
              if (product.providerCode !== '' && product.providerCode !== null) {
                this.writeBoldAndNormalText((i18n.t('records.label.titles.providerCode') + ": " + "**" + product.providerCode + "**"), provider[0], provider[1], 8, 8);
              }
              if (product.netWeight !== '' && product.netWeight !== null) {
                this.writeBoldAndNormalText((i18n.t('records.label.titles.netWeight') + ": " + "**" + parseFloat(product.netWeight).toFixed(this.decimalFormat) + "**"), netWeight[0], netWeight[1], 10, 8);
              }
              this.setFontConfig(6, '#232323', 'normal', 'normal');
              this.doc.text('Powered by AgTrace', agtrace[0], agtrace[1], {'angle': 90});

              this.doc.addImage(lineas, 'png', tracking[0], tracking[1], 297.5, 12);
              const x = 298;
              offset = [offset[0] + x, offset[1]];
              QR = [QR[0] + x, QR[1]];
              yProduct = [yProduct[0] + x, yProduct[1]];
              barText = [barText[0] + x, barText[1]];
              dataLabel = [dataLabel[0] + x, dataLabel[1]];
              provider = [provider[0] + x, provider[1]];
              netWeight = [netWeight[0] + x, netWeight[1]];
              agtrace = [agtrace[0] + x, agtrace[1]];
              tracking = [tracking[0] + x, tracking[1]];
            }
            const y = 170;
            offset = [offset[0], offset[1] + y];
            QR = [QR[0], QR[1] + y];
            yProduct = [yProduct[0], yProduct[1] + y];
            barText = [barText[0], barText[1] + y];
            dataLabel = [dataLabel[0], dataLabel[1] + y];
            provider = [provider[0], provider[1] + y];
            netWeight = [netWeight[0], netWeight[1] + y];
            agtrace = [agtrace[0], agtrace[1] + y];
            tracking = [tracking[0], tracking[1] + y];
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })

      }
      if (this.formData.labelType.id + this.formData.printType === '10A4') {
        this.formData.save = true
        _.forEach(this.formData.productsData, (product, index) => {

          let offset = [0, 10]
          let QRTOP = [5, 6]
          let QR = [240, 6]
          let yTop = [65, 15]
          let dataLabel = [5, 80]
          let provider = [180, 120]
          let netWeight = [180, 130]
          let agtrace = [295, 148]
          let tracking = [0, 150]

          product.code = uuidv4()

          const link = process.env.VUE_APP_BACK_FRONT + '/label_traceability/' + product.code

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });
          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
          })

          const imgBase64Data = qrcode._oDrawing._elCanvas.toDataURL('image/png')

          for (let i = 0; i < 5; i++) {
            offset = [20, offset[1]]
            QRTOP = [5, QRTOP[1]]
            QR = [230, QR[1]]
            yTop = [70, yTop[1]]
            dataLabel = [5, dataLabel[1]]
            provider = [180, provider[1]]
            netWeight = [180, netWeight[1]]
            agtrace = [295, agtrace[1]]
            tracking = [0, tracking[1]]
            for (let l = 0; l < 2; l++) {
              // Render the img QRCode Product
              this.doc.addImage(qrcodeProduct, 'png', QRTOP[0], QRTOP[1], 60, 60);
              // Render the img QRCode Traceability
              this.doc.addImage(imgBase64Data, 'png', QR[0], QR[1], 60, 60)
              // Render data TOP
              this.getTopLabelDataGS1v2(yTop[0],yTop[1], 7,7,index, 110, 90, 35);
              // Render Label data
              this.getLabelDataGS1v2(dataLabel[0], dataLabel[1], 8, 8, index, product)

              this.setFontConfig(6, '#232323', 'normal', 'normal')
              this.doc.text('Powered by AgTrace', agtrace[0], agtrace[1], { 'angle': 90 })

              this.doc.addImage(lineas, 'png', tracking[0], tracking[1], 297.5, 12)
              const x = 298
              offset = [offset[0] + x, offset[1]]
              QRTOP = [QRTOP[0] + x, QRTOP[1]]
              QR = [QR[0] + x, QR[1]]
              yTop = [yTop[0] + x, yTop[1]]
              dataLabel = [dataLabel[0] + x, dataLabel[1]]
              provider = [provider[0] + x, provider[1]]
              netWeight = [netWeight[0] + x, netWeight[1]]
              agtrace = [agtrace[0] + x, agtrace[1]]
              tracking = [tracking[0] + x, tracking[1]]
            }
            const y = 170
            offset = [offset[0], offset[1] + y]
            QRTOP = [QRTOP[0], QRTOP[1] + y]
            QR = [QR[0], QR[1] + y]
            yTop = [yTop[0], yTop[1] + y]
            dataLabel = [dataLabel[0], dataLabel[1] + y]
            provider = [provider[0], provider[1] + y]
            netWeight = [netWeight[0], netWeight[1] + y]
            agtrace = [agtrace[0], agtrace[1] + y]
            tracking = [tracking[0], tracking[1] + y]
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage()
          }
        })

      }
      if (this.formData.labelType.id + this.formData.printType === '3A4') {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let QR = [50, 0];
          let yProduct = [5, 9];
          let limit = 10;

          product.code = uuidv4();

          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text: process.env.VUE_APP_BACK_FRONT + '/product_traceability/' + product.code,
          });

          const imgBase64Data = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          for (let i = 0; i < 5; i++) {
            QR = [70, QR[1]];
            yProduct = [5, yProduct[1]];
            for (let l = 0; l < 5; l++) {
              // Render the img QRCode
              this.doc.addImage(imgBase64Data, 'png', QR[0], QR[1], 90, 90);
              // Render Product Data
              let y = yProduct[1];
              this.setFontConfig(10, '#232323', 'normal', 'bold');
              this.doc.text(i18n.t('records.label.titles.product'), yProduct[0], y);
              y += 10;
              this.setFontConfig(10, '#232323', 'normal', 'normal');
              const splitText = this.spliter(this.getProduct(index), 10)
              _.forEach(splitText, t => {
                let text = this.doc.splitTextToSize(t, 80) + `${t.length === 10 ? "-" : ""}`;
                this.doc.text(text, yProduct[0], y);
                y += 10;
              })
              this.setFontConfig(10, '#232323', 'normal', 'bold');
              this.doc.text(this.getLoteSafraName(index).name, yProduct[0], y);
              y += 10;
              this.setFontConfig(10, '#232323', 'normal', 'normal');
              if (!this.formData.productsData[index].expirationDate || !this.formData.productsData[index].packingDate) {
                limit = 25;
              }
              let splitTitle2 = this.doc.splitTextToSize(this.getLoteSafraName(index).record_value.substring(0, limit), 60);
              this.doc.text(splitTitle2, yProduct[0], y);
              if (!this.formData.productsData[index].expirationDate || !this.formData.productsData[index].packingDate) {
                y += 15;
              }
              if (this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) {
                y += 10;
                this.setFontConfig(10, '#232323', 'normal', 'bold');
                this.doc.text(i18n.t('records.label.titles.packing'), yProduct[0], y);
                y += 10;
                this.setFontConfig(10, '#232323', 'normal', 'normal');
                this.doc.text(this.formData.productsData[index].packingDate, yProduct[0], y);
              }
              if (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null) {
                y += 10;
                this.setFontConfig(10, '#232323', 'normal', 'bold');
                this.doc.text(i18n.t('records.label.titles.expiration'), yProduct[0], y);
                y += 10;
                this.setFontConfig(10, '#232323', 'normal', 'normal');
                this.doc.text(this.formData.productsData[index].expirationDate, yProduct[0], y);
              }
              const x = 170;
              QR = [QR[0] + x, QR[1]];
              yProduct = [yProduct[0] + x, yProduct[1]];
            }
            const y = 125;
            QR = [QR[0], QR[1] + y];
            yProduct = [yProduct[0], yProduct[1] + y];
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })

      }
      if (this.formData.labelType.id + this.formData.printType === '4DUPLO') {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let yOffset = 10;
          let yQR = 0;
          let yProduct = 18;
          let dataLabel = 50;
          let provider = 105;
          let netWeight = 118;
          let agtrace = 130;
          let tracking = 132;

          product.code = uuidv4();

          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text: process.env.VUE_APP_BACK_FRONT + '/product_traceability/' + product.code,
          });

          const imgBase64Data = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          for (let i = 0; i < 2; i++) {
            // Render the img QRCode
            this.doc.addImage(imgBase64Data, 'png', 340, yQR, 90, 90);
            // Render Product Name
            this.setFontConfig(12, '#232323', 'normal', 'bold');
            let splitTitle = this.doc.splitTextToSize(this.getProduct(index), 270);
            this.doc.text(splitTitle, 80, yProduct);
            // Render Label data
            this.getLabelData(5, dataLabel, 8, 8, index);
            if (product.netWeight !== '' && product.netWeight !== null) {
              this.writeBoldAndNormalText((i18n.t('records.label.titles.netWeight') + ": " + "**" + parseFloat(product.netWeight).toFixed(this.decimalFormat) + ' ' + product.unit.code + "**"), 250, netWeight, 12, 10);
            }
            this.setFontConfig(8, '#232323', 'normal', 'normal');
            this.doc.text('Powered by AgTrace', 445, agtrace, {'angle': 90});

            this.doc.addImage(lineas, 'png', 0, tracking, 453, 12);
            yOffset += 145;
            yQR += 145;
            yProduct += 145;
            dataLabel += 145;
            provider += 145;
            netWeight += 145;
            agtrace += 145;
            tracking += 145;
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '5DUPLO') {
        _.forEach(this.formData.productsData, (product, index) => {
          let yOffset = 10;
          let rect = 7;
          let xQR = 50;
          let xLine = 12;
          let xLine2 = 128;
          let xProduct = 30;
          let xProductTitle = 70;
          let netWeight = 118;
          let agtrace = 130;
          let fontTitle = 7;
          let fontData = 7;
          let yProduct = this.getObjectProduct(index);

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });

          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          if (this.$route.params.id === 'recordsLabel') {
            this.formData.productsData[index].form.lot = this.getLoteSafraName(index).record_value;
          } else if (this.$route.params.id === 'product') {
            console.log(this.formData)
            this.formData.productsData[index].form.lot = this.formData.productsData[index].lot;
          }
          for (let i = 0; i < 2; i++) {
            let yData = 67;
            let yDataTitle = 79;
            // Render the img QRCode
            this.doc.addImage(qrcodeProduct, 'png', xQR, 165, 50, 50);
            // Render Product Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'normal');
            this.doc.text(i18n.t(('product.fields.name')) + ': ', rect + 6, 15);
            if (yProduct) {
              // Render Product Name
              this.setFontConfig(9, '#232323', 'normal', 'bold');
              let splitTitle = this.doc.splitTextToSize(this.translate(yProduct.language_key, true).substring(0, 50), 124);
              this.doc.text(splitTitle, xProductTitle, 28, 'center');
            }
            // Render Line
            this.doc.line(xLine, 50, xLine2, 50);
            if (yProduct) {
              // Render Product GTIN Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
              this.doc.text(i18n.t(('product.fields.codeGTIN')) + ': ', rect + 6, 60);
              // Render Product GTIN Number
              this.setFontConfig(fontData, '#232323', 'normal', 'normal');
              this.doc.text(yProduct.gtin, xProductTitle, yData, 'center');
            }
            // Render Product Lote Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.lot')) + ': ', rect + 6, yDataTitle);
            // Render Product Lote Number
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            yData += 18;
            this.doc.text(this.formData.productsData[index].form.lot, xProductTitle, yData, 'center');
            if (this.formData.productsData[index].netWeight) {
              // Render Product Cant Unit Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
              yDataTitle += 17;
              this.doc.text(i18n.t(('product.label.weight')) + ': ', rect + 6, yDataTitle);
              // Render Product Cant Unit
              this.setFontConfig(fontData, '#232323', 'normal', 'normal');
              yData += 18;
              if (this.formData.productsData[index].netWeight !== '' && this.formData.productsData[index].netWeight !== null)
                this.doc.text((this.formData.productsData[index].netWeight + ' ' + this.formData.productsData[index].unit.unit_name).substring(0, 18), xProductTitle, yData, 'center');
            }

            if (this.formData.productsData[index].manufacturingDate) {
              // Render Product Data Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
              yDataTitle += 17;
              this.doc.text(i18n.t(('product.label.manufacturingDate')) + ': ', rect + 6, yDataTitle);
              // Render Product Data
              this.setFontConfig(fontData, '#232323', 'normal', 'normal');
              yData += 18;
              this.doc.text(moment(this.formData.productsData[index].manufacturingDate).format('DD/MM/YYYY'), xProductTitle, yData, 'center');
            }

            if (this.formData.productsData[index].expirationDate) {
              // Render Product Data Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
              yDataTitle += 17;
              this.doc.text(i18n.t(('product.label.expirationDate')) + ': ', rect + 6, yDataTitle);
              // Render Product Data
              this.setFontConfig(fontData, '#232323', 'normal', 'normal');
              yData += 18;
              this.doc.text(moment(this.formData.productsData[index].expirationDate).format('DD/MM/YYYY'), xProductTitle, yData, 'center');
            }
            // Render Details
            let splitDetails = this.doc.splitTextToSize(this.formData.productsData[index].additionalInformation, 170);
            this.setFontConfig(6, '#232323', 'normal', 'normal');
            yData += 10;
            this.doc.text(splitDetails, xLine, yData);
            // Render Line
            this.formData.productsData[index].expirationDate ? yData += 10 : yData += 30;
            this.doc.line(xLine, yData, xLine2, yData);
            // Render AgTrace
            this.setFontConfig(6, '#232323', 'normal', 'normal');
            this.doc.text('Powered by AgTrace', agtrace, 219, {'angle': 90});
            // Render border
            this.doc.rect(rect, 6, 128, 215, 'S');

            yOffset += 145;
            xQR += 140;
            xProduct += 145;
            netWeight += 145;
            agtrace += 142;
            rect += 142;
            xLine += 142;
            xLine2 += 142;
            xProductTitle += 142;
          }
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '5A4') {
        _.forEach(this.formData.productsData, (product, index) => {
          let rect = [7, 6];
          let properties = [13, 15];
          let QR = [50,165];
          let line = [12,50];
          let line2 = [128, 67];
          let yData = [67];
          let xProductTitle = [70, 28];
          let netWeight = [118,67];
          let agtrace = [130,219];
          let fontTitle = 8;
          let fontData = 8;
          let yProduct = this.getObjectProduct(index);

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });

          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          if (this.$route.params.id === 'recordsLabel') {
            this.formData.productsData[index].form.lot = this.getLoteSafraName(index).record_value;
          } else if (this.$route.params.id === 'product') {
            console.log(this.formData)
            this.formData.productsData[index].form.lot = this.formData.productsData[index].lot;
          }
          for (let l = 0; l < 3; l++) {

            QR = [50, QR[1]]
            netWeight = [118, netWeight[1]]
            rect = [7, rect[1]];
            agtrace = [130, agtrace[1]];
            properties = [13, properties[1]]
            line = [12, line[1]]
            line2 = [128, line2[1]]
            xProductTitle = [70, xProductTitle[1]]
            yData =[yData[0]]
            let yDataCopy = yData[0];

            for (let i = 0; i < 4; i++) {

              // Render the img QRCode
              this.doc.addImage(qrcodeProduct, 'png', QR[0], QR[1], 50, 50)
              // Render Product Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'normal')
              this.doc.text(i18n.t(('product.fields.name')) + ': ', properties[0], properties[1])
              if (yProduct) {
                // Render Product Name
                this.setFontConfig(9, '#232323', 'normal', 'bold')
                let splitTitle = this.doc.splitTextToSize(this.translate(yProduct.language_key, true).substring(0, 50), 124)
                this.doc.text(splitTitle, xProductTitle[0], xProductTitle[1], 'center')
              }
              // Render Line
              this.doc.line(line[0], line[1], line2[0], line[1])
              if (yProduct) {
                // Render Product GTIN Title
                this.setFontConfig(fontTitle, '#232323', 'normal', 'bold')
                this.doc.text(i18n.t(('product.fields.codeGTIN')) + ': ', properties[0], properties[1] + 45)
                // Render Product GTIN Number
                this.setFontConfig(fontData, '#232323', 'normal', 'normal')
                this.doc.text(yProduct.gtin, xProductTitle[0], yDataCopy, 'center')
              }
              // Render Product Lote Title
              this.setFontConfig(fontTitle, '#232323', 'normal', 'bold')
              this.doc.text(i18n.t(('product.label.lot')) + ': ', properties[0], properties[1] + 64)
              // Render Product Lote Number
              this.setFontConfig(fontData, '#232323', 'normal', 'normal')
              this.doc.text(this.formData.productsData[index].form.lot, xProductTitle[0], yDataCopy += 18, 'center')
              if (this.formData.productsData[index].netWeight) {
                // Render Product Cant Unit Title
                this.setFontConfig(fontTitle, '#232323', 'normal', 'bold')
                this.doc.text(i18n.t(('product.label.weight')) + ': ', properties[0], properties[1] + 81)
                // Render Product Cant Unit
                this.setFontConfig(fontData, '#232323', 'normal', 'normal')
                if (this.formData.productsData[index].netWeight !== '' && this.formData.productsData[index].netWeight !== null)
                  this.doc.text((this.formData.productsData[index].netWeight + ' ' + this.formData.productsData[index].unit.unit_name).substring(0, 18), xProductTitle[0], yDataCopy += 18, 'center')
              }

              if (this.formData.productsData[index].manufacturingDate) {
                // Render Product Data Title
                this.setFontConfig(fontTitle, '#232323', 'normal', 'bold')
                this.doc.text(i18n.t(('product.label.manufacturingDate')) + ': ', properties[0], properties[1] + 98)
                // Render Product Data
                this.setFontConfig(fontData, '#232323', 'normal', 'normal')
                this.doc.text(moment(this.formData.productsData[index].manufacturingDate).format('DD/MM/YYYY'), xProductTitle[0], yDataCopy += 18, 'center')
              }

              if (this.formData.productsData[index].expirationDate) {
                // Render Product Data Title
                this.setFontConfig(fontTitle, '#232323', 'normal', 'bold')
                this.doc.text(i18n.t(('product.label.expirationDate')) + ': ', properties[0], properties[1] + 115)
                // Render Product Data
                this.setFontConfig(fontData, '#232323', 'normal', 'normal')
                this.doc.text(moment(this.formData.productsData[index].expirationDate).format('DD/MM/YYYY'), xProductTitle[0], yDataCopy += 18, 'center')
              }
              // Render Details
              let splitDetails = this.doc.splitTextToSize(this.formData.productsData[index].additionalInformation, 170)
              this.setFontConfig(6, '#232323', 'normal', 'normal')
              this.doc.text(splitDetails, line[0], yDataCopy += 10)
              // Render Line
              let line2copy = this.formData.productsData[index].expirationDate ? (yDataCopy + 10) : (yDataCopy + 30)

              this.doc.line(line[0], line2copy, line2[0], line2copy)
              // Render AgTrace
              this.setFontConfig(6, '#232323', 'normal', 'normal')
              this.doc.text('Powered by AgTrace', agtrace[0], agtrace[1], { 'angle': 90 })
              // Render border
              this.doc.rect(rect[0], rect[1], 128, 215, 'S')

              QR = [QR[0] + 140, QR[1]]
              netWeight = [netWeight[0] + 145, netWeight[1]]
              agtrace = [agtrace[0] + 142, agtrace[1]]
              rect = [rect[0] + 142, rect[1]]
              properties = [properties[0] + 142, properties[1]]
              line = [line[0] + 142, line[1]]
              line2 = [line2[0] + 142, line2[1]]
              xProductTitle = [xProductTitle[0] + 142, xProductTitle[1]]
              yData[0] = yData[0]
              yDataCopy = yData[0];

            }
            const y = 240
            QR = [QR[0], QR[1] + y]
            netWeight = [netWeight[0], netWeight[1] + y]
            agtrace = [agtrace[0], agtrace[1] + y]
            rect = [rect[0], rect[1] + y]
            properties = [properties[0], properties[1] + y]
            line = [line[0], line[1] + y]
            line2 = [line2[0], line2[1] + y]
            xProductTitle = [xProductTitle[0], xProductTitle[1] + y]
            yData[0] =  yData[0] + y;
          }


          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '8SIMPLE') {
        _.forEach(this.formData.productsData, (product, index) => {
          /*const qrcodeProduct = document.getElementsByClassName('qrcode')[index];*/
          let rect = 9;
          let xLine = 12;
          let xLine2 = 167;
          let agtrace = 280;
          let fontTitle = 7;
          let fontData = 9;
          let yProduct = this.getObjectProduct(index);

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });

          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          if (this.$route.params.id === 'recordsLabel') {
            this.formData.productsData[index].form.lot = this.getLoteSafraName(index).record_value;
            // if (this.formData.process_type !== 'AGRICULTURAL') {
            //     this.formData.netWeight = result.cant;
            // }
          } else if (this.$route.params.id === 'product') {
            console.log(this.formData)
            this.formData.productsData[index].form.lot = this.formData.productsData[index].lot;
          }
          let yData = 147;
          let yDataTitle = 167;
          // Render the img QRCode
          this.doc.addImage(qrcodeProduct, 'png', 280, 60, 55, 55, 'QR', 'NONE', 90);
          // Render Product Title
          this.setFontConfig(fontTitle, '#232323', 'normal', 'normal');
          this.doc.text(i18n.t(('product.fields.name')) + ': ', rect + 8, yDataTitle, {angle: 90, rotationDirection: 1});
          if (yProduct) {
            // Render Product Name
            this.setFontConfig(11, '#232323', 'normal', 'bold');
            let splitTitle = this.doc.splitTextToSize(this.translate(yProduct.language_key, true).substring(0, 50), 124);
            this.doc.text(splitTitle, rect + 30, 150, { angle: 90, rotationDirection: 1});
          }
          // Render Line
          this.doc.line(70, xLine, 70, xLine2);
          if (yProduct) {
            // Render Product GTIN Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.fields.codeGTIN')) + ': ', rect + 70, yDataTitle, {
              angle: 90,
              rotationDirection: 1
            });
            // Render Product GTIN Number
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            this.doc.text(yProduct.gtin, rect + 80, yData, { angle: 90, rotationDirection: 1});
          }
          // Render Product Lote Title
          this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
          this.doc.text(i18n.t(('product.label.lot')) + ': ', rect + 95, yDataTitle, {angle: 90, rotationDirection: 1});
          // Render Product Lote Number
          this.setFontConfig(fontData, '#232323', 'normal', 'normal');
          this.doc.text(this.formData.productsData[index].form.lot, rect + 105, yData, {angle: 90, rotationDirection: 1});
          if (this.formData.productsData[index].netWeight) {
            // Render Product Cant Unit Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.weight')) + ': ', rect + 120, yDataTitle, {
              angle: 90,
              rotationDirection: 1
            });
            // Render Product Cant Unit
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            if (this.formData.productsData[index].netWeight !== '' && this.formData.productsData[index].netWeight !== null)
              this.doc.text((this.formData.productsData[index].netWeight + ' ' + this.formData.productsData[index].unit.unit_name).substring(0, 18), rect + 132, yData, {
                angle: 90,
                rotationDirection: 1
              });
          }

          if (this.formData.productsData[index].manufacturingDate) {
            // Render Product Data Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            yDataTitle += 0;
            this.doc.text(i18n.t(('product.label.manufacturingDate')) + ': ', rect + 145, yDataTitle, {
              angle: 90,
              rotationDirection: 1
            });
            // Render Product Data
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            yData += 0;
            this.doc.text(moment(this.formData.productsData[index].manufacturingDate).format('DD/MM/YYYY'), rect + 155, yData, {
              angle: 90,
              rotationDirection: 1
            });
          }

          if (this.formData.productsData[index].expirationDate) {
            // Render Product Data Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.expirationDate')) + ': ', rect + 170, yDataTitle, {
              angle: 90,
              rotationDirection: 1
            });
            // Render Product Data
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            this.doc.text(moment(this.formData.productsData[index].expirationDate).format('DD/MM/YYYY'), rect + 180, yData, {
              angle: 90,
              rotationDirection: 1
            });
          }
          // Render Details
          let splitDetails = this.doc.splitTextToSize(this.formData.productsData[index].additionalInformation, 170);
          this.setFontConfig(8, '#232323', 'normal', 'normal');
          yData += 19;
          this.doc.text(splitDetails, xLine + 190, yData, {angle: 90, rotationDirection: 1});
          // Render Line
          // this.formData.productsData[index].expirationDate ? yData += 10 : yData += 30;
          // yData += 25;
          this.doc.line(yData + 50, xLine, yData + 50, xLine2);
          // Render AgTrace
          this.setFontConfig(6, '#232323', 'normal', 'normal');
          this.doc.text('Powered by AgTrace', agtrace, 13, {'angle': 180});
          // Render border
          this.doc.rect(rect, 6, 280, 166, 'S');
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '9SIMPLE') {
        _.forEach(this.formData.productsData, (product, index) => {
          /*const qrcodeProduct = document.getElementsByClassName('qrcode')[index];*/
          let rect = 9;
          let xLine = 15;
          let xLine2 = 167;
          let agtrace = 280;
          let fontTitle = 7;
          let fontData = 9;
          let yProduct = this.getObjectProduct(index);

          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text: text
          });

          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          if (this.$route.params.id === 'recordsLabel') {
            this.formData.productsData[index].form.lot = this.getLoteSafraName(index).record_value;
            // if (this.formData.process_type !== 'AGRICULTURAL') {
            //     this.formData.netWeight = result.cant;
            // }
          } else if (this.$route.params.id === 'product') {
            this.formData.productsData[index].form.lot = this.formData.productsData[index].lot;
          }
          let yData = 17;
          let yDataTitle = 13;
          // Render the img QRCode
          this.doc.addImage(qrcodeProduct, 'png', 65, 225, 55, 55, 'QR');
          // Render Product Title
          this.setFontConfig(fontTitle, '#232323', 'normal', 'normal');
          this.doc.text(i18n.t(('product.fields.name')) + ': ', yDataTitle, rect + 8);
          if (yProduct) {
            // Render Product Name
            this.setFontConfig(11, '#232323', 'normal', 'bold');
            let splitTitle = this.doc.splitTextToSize(this.translate(yProduct.language_key, true).substring(0, 50), 124);
            this.doc.text(splitTitle, 18, rect + 20);
          }
          // Render Line
          this.doc.line( xLine, 70, xLine2, 70);
          if (yProduct) {
            // Render Product GTIN Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.fields.codeGTIN')) + ': ', yDataTitle, rect + 70);
            // Render Product GTIN Number
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            this.doc.text(yProduct.gtin, yData, rect + 80);
          }
          // Render Product Lote Title
          this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
          this.doc.text(i18n.t(('product.label.lot')) + ': ', yDataTitle, rect + 95);
          // Render Product Lote Number
          this.setFontConfig(fontData, '#232323', 'normal', 'normal');
          this.doc.text(this.formData.productsData[index].form.lot, yData, rect + 105);
          if (this.formData.productsData[index].netWeight) {
            // Render Product Cant Unit Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.weight')) + ': ', yDataTitle, rect + 120);
            // Render Product Cant Unit
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            if (this.formData.productsData[index].netWeight !== '' && this.formData.productsData[index].netWeight !== null)
              this.doc.text((this.formData.productsData[index].netWeight + ' ' + this.formData.productsData[index].unit.unit_name).substring(0, 18), yData, rect + 130);
          }

          if (this.formData.productsData[index].manufacturingDate) {
            // Render Product Data Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.manufacturingDate')) + ': ', yDataTitle, rect + 145);
            // Render Product Data
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            this.doc.text(moment(this.formData.productsData[index].manufacturingDate).format('DD/MM/YYYY'), yData, rect + 155);
          }

          if (this.formData.productsData[index].expirationDate) {
            // Render Product Data Title
            this.setFontConfig(fontTitle, '#232323', 'normal', 'bold');
            this.doc.text(i18n.t(('product.label.expirationDate')) + ': ', yDataTitle, rect + 170);
            // Render Product Data
            this.setFontConfig(fontData, '#232323', 'normal', 'normal');
            this.doc.text(moment(this.formData.productsData[index].expirationDate).format('DD/MM/YYYY'), yData, rect + 180);
          }
          // Render Details
          let splitDetails = this.doc.splitTextToSize(this.formData.productsData[index].additionalInformation, 170);
          this.setFontConfig(8, '#232323', 'normal', 'normal');
          this.doc.text(splitDetails, yData-4, xLine + 195);
          // Render Line
          // this.formData.productsData[index].expirationDate ? yData += 10 : yData += 30;
          // yData += 25;
          this.doc.line( xLine, yData + 200, xLine2,yData + 200);
          // Render AgTrace
          this.setFontConfig(6, '#232323', 'normal', 'normal');
          this.doc.text('Powered by AgTrace',  170, agtrace, {'angle': 90});
          // Render border
          this.doc.rect(rect, 6, 166,280, 'S');
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      if (this.formData.labelType.id + this.formData.printType === '6SIMPLE') {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let yQR = 90.0394;
          let yProduct = 50;
          let dataLabel = 76.0394;
          let agtrace = 150;

          product.code = uuidv4();

          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text: process.env.VUE_APP_BACK_FRONT + '/product_traceability/' + product.code,
          });

          const imgBase64Data = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          // Render the img QRCode
          this.doc.addImage(imgBase64Data, 'png', 40, yQR, 65, 65);
          // Render Product Name
          this.setFontConfig(12, '#232323', 'normal', 'bold');
          let splitTitle = this.doc.splitTextToSize(product.product_value, 200);
          this.doc.text(splitTitle, 127.559, yProduct);
          // Render Label data
          this.getLabelData(127.559, dataLabel, 9, 9, index);
          if (this.formData.productsData[index].additionalInformation !== '' && this.formData.productsData[index].additionalInformation !== null) {
            let splitTitle = this.doc.splitTextToSize(this.formData.productsData[index].additionalInformation, 260);
            this.doc.text(splitTitle, 127.559, 134, 'left');
            // this.writeBoldAndNormalText((splitTitle), x, y, fontSizeP, fontSizeP);
          }
          if (product.netWeight !== '' && product.netWeight !== null) {
            this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.netWeight') + "**" + " : " + parseFloat(product.netWeight).toFixed(this.decimalFormat) + ' ' + product.unit.code), 127.559, 155, 10, 10);
          }
          this.setFontConfig(8, '#232323', 'normal', 'normal');
          this.doc.text('Powered by AgTrace', 30, agtrace, {'angle': 90});
          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        });

      }
      if (this.formData.labelType.id + this.formData.printType === '7DUPLO') {
        let yOffset = 10;
        let rect = 7;
        let xQR = 38;
        let xLine = 12;
        let xLine2 = 128;
        let xProduct = 30;
        let xProductTitle = 70;
        let netWeight = 118;
        let agtrace = 130;
        let fontTitle = 7;
        for (let i = 0; i < 2; i++) {
          // Render the img QRCode
          this.doc.addImage(qrcode.src, 'png', xQR, 90, 70, 70);
          // Render Pre Record Title
          this.setFontConfig(fontTitle, '#232323', 'normal', 'normal');
          this.doc.text(i18n.t(('pre-records.fields.name')) + ': ', rect + 6, 15);
          // Render Pre Record Name
          this.setFontConfig(9, '#232323', 'normal', 'bold');
          let splitTitle = this.doc.splitTextToSize(this.translate(this.pre_records_barcode.languageKey, true).substring(0, 50), 124);
          this.doc.text(splitTitle, xProductTitle, 28, 'center');
          // Render Line
          this.doc.line(xLine, 50, xLine2, 50);
          // Render AgTrace
          this.setFontConfig(6, '#232323', 'normal', 'normal');
          this.doc.text('Powered by AgTrace', agtrace, 219, {'angle': 90});
          // Render border
          this.doc.rect(rect, 6, 128, 215, 'S');

          yOffset += 145;
          xQR += 140;
          xProduct += 145;
          netWeight += 145;
          agtrace += 142;
          rect += 142;
          xLine += 142;
          xLine2 += 142;
          xProductTitle += 142;
        }
      }
      if (this.formData.labelType.id + this.formData.printType === '11SIMPLE') {
        this.formData.save = true;
        let logoCompany = null;
        const logoAgTrace = document.querySelector('img#agtracelogo');
        if(this.company.length > 0 && this.company[0].logo){
          logoCompany = document.querySelector('img#companylogo');
        }

        _.forEach(this.formData.productsData, (product, index) => {
          let yQR = 5;
          let yTop = 15;
          let dataLabel = 87;
          let agtrace = 130;
          let tracking = 132;

          let xLine = 5;
          let xLine2 = 278;

          // UUID Code
          product.code = uuidv4();

          // QR Link
          const link = process.env.VUE_APP_BACK_FRONT + '/label_traceability/' + product.code
          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
          });
          const imgBase64Data = qrcode._oDrawing._elCanvas.toDataURL("image/png")

          // QR Code 128
          const text = this.getQRCode128(index)
          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:text
          });
          const qrcodeProduct = qrcodjs._oDrawing._elCanvas.toDataURL("image/png")

          // PDF
          // Render Logo Company
          if(this.company.length > 0 && this.company[0].logo && logoCompany){
            this.doc.addImage(logoCompany, 'png', 20, 7, 70, 70);
          }
          // Render the img QRCode Traceability
          this.doc.addImage(imgBase64Data, 'png', 112, 9, 70, 70);
          // Render Logo Agtrace
          this.doc.addImage(logoAgTrace, 'png', 192, 24, 80, 32);
          // Line
          this.doc.line(xLine, 83, xLine2, 83);
          // Render data TOP
          this.getTopLabel10x15(10,98, 9,9,index, 200, 180, 50);
          // Line
          this.doc.line(xLine, 167, xLine2, 165);
          // Render Label data
          this.getLabelDataProduct10x15(10, 182, 9, 9, index, product);
          // Line
          this.doc.line(xLine, 238, xLine2, 238);
          // Render the img QRCode Product
          this.doc.addImage(qrcodeProduct, 'png', 112, 245, 70, 70);
          // Render Barcode 128
          let textbarcode = this.getBarCode128(['u'], index)

          let JsBarcode = require('jsbarcode');

          let canvas = document.createElement("canvas");
          JsBarcode(canvas, textbarcode, {
            format: 'CODE128',
            font: 'Arial',
            width:1.4,
            height:30,
            displayValue: true,
            fontSize: 12
          });
          const barcode = canvas.toDataURL("image/png");

          this.doc.addImage(barcode, 'png', 10, 317);

          // Line
          this.doc.line(xLine, 373, xLine2, 373);

          // Render Extra Information
          if (this.formData.productsData[index].additionalInformation !== '' && this.formData.productsData[index].additionalInformation !== null) {
            const adicional = this.doc.splitTextToSize(this.isNotExist(this.formData.productsData[index].additionalInformation), 265);
            this.doc.text(adicional, 10, 387);
          }
          // Render Traceability Line
          this.doc.addImage(lineas, 'png', 0, 413, 283, 9);

          if (this.formData.productsData.length !== index + 1) {
            this.doc.addPage();
          }
        })
      }
      // PDF ready
      this.pdfReady = true;
      // Return PDF data string
      return this.doc.output('datauristring');
    },
    getLabelData(x, y, fontSizeG, fontSizeP, index) {
      this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.distributedBy') + "**" + " : " + this.formData.productsData[index].form.distributedBy + ' ' + this.formData.productsData[index].form.documentValueDistributed), x, y, fontSizeG, fontSizeG);
      this.setFontConfig(fontSizeP, '#232323', 'normal', 'normal');
      y += 9;
      this.doc.text(this.formData.productsData[index].form.allAddressDistributed, x, y);
      y += 10;
      this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.producedBy') + "**" + " : " + this.formData.productsData[index].form.producedBy + ' ' + this.formData.productsData[index].form.documentValueProduced), x, y, fontSizeG, fontSizeG);
      this.setFontConfig(fontSizeP, '#232323', 'normal', 'normal');
      y += 9;
      this.doc.text(this.formData.productsData[index].form.allAddressProduced, x, y);
      let datas = '';
      if ((this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) || (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null)) {
        y += 10;
        if (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null) {
          datas = "**" + (this.formData.labelType.id + this.formData.printType === '4DUPLO' || this.formData.labelType.id + this.formData.printType === '6SIMPLE' ? i18n.t('records.label.titles.harvestDate') : i18n.t('records.label.titles.expirationDate')) + "**" + "  : " + this.formData.productsData[index].expirationDate;
        }
        if (this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) {
          datas = datas + "   " + "**" + i18n.t('records.label.titles.packingDate') + "**" + ": " + this.formData.productsData[index].packingDate;
        }
        this.writeBoldAndNormalText(datas, x, y, fontSizeG, fontSizeG);
      }
      y += 10;
      this.writeBoldAndNormalText(("**" + this.getLoteSafraName(index).name + "**" + ": " + this.getLoteSafraName(index).record_value), x, y, fontSizeG, fontSizeG);
      if (this.formData.labelType.id + this.formData.printType !== '6SIMPLE') {
        if (this.formData.productsData[index].additionalInformation !== '' && this.formData.productsData[index].additionalInformation !== null) {
          y += 8;
          this.writeBoldAndNormalText((this.formData.productsData[index].additionalInformation), x, y, fontSizeP, fontSizeP);
        }
      }

    },

    toStartCase(text) {
      const words = text.split(" ");

      return words.map((word) => {
        if (word)
          return word[0].toUpperCase() + word.substring(1).toLowerCase();
      }).join(" ");
    },

    getTopLabel10x15(x, y, fontSizeG, fontSizeP, index, splitSizeAddress, splitSizeLoc, addressColSpan) {
      this.writeBoldAndNormalText(("**" + i18n.t('labels.titles.originData') + "**"), x, y, fontSizeG + 2, fontSizeG + 2);
      y += fontSizeG + 3;
      const loc1 = this.toStartCase(this.isNotExist(this.formData.productsData[index].form.primary_origin_name))
      const loc2 = this.toStartCase(this.isNotExist(this.formData.productsData[index].form.sdi_origin_name));
      this.writeBoldAndNormalText(("**" + i18n.t('records.fields.origin') + "**" + " : "), x, y, fontSizeG, fontSizeG);

      y += fontSizeG + 2;
      this.doc.text(loc1, (x), y);
      y += fontSizeG + 2;
      this.doc.text(loc2, (x), y);
      y += fontSizeG + 2;

      const text = this.doc.splitTextToSize(this.isNotExist(this.formData.productsData[index].form.allAddressProduced), splitSizeAddress);
      this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.allAddressProduced') + "**" + " :    "), x, y, fontSizeG, fontSizeG);
      this.doc.text(text, (x + addressColSpan), y);
      if (text && text.length > 1) {
        y += fontSizeG + 2;
      }
      if (text && text.length > 2) {
        y += fontSizeG + 2;
      }
      y += fontSizeG + 2;

      if (isValidLatLong(this.formData.productsData[index].form.sdi_origin_lat, true) && isValidLatLong(this.formData.productsData[index].form.sdi_destination_lat, true)) {
        this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.lat') + "**" + " : " + convertLatitude(this.formData.productsData[index].form.sdi_origin_lat) + '** - **' + "**" + i18n.t('records.label.titles.long') + "**" + " : " + convertLongitude(this.formData.productsData[index].form.sdi_origin_long)), x, y, fontSizeG, fontSizeG);
      }
    },

    getLabelDataProduct10x15(x, y, fontSizeG, fontSizeP, index, productObject){
      this.writeBoldAndNormalText(("**" + i18n.t('labels.titles.productData') + "**"), x, y, fontSizeG + 2, fontSizeG + 2);
      y += fontSizeG + 3;
      let product = this.getObjectProduct(index);
      if (product) {
        this.writeBoldAndNormalText(( i18n.t('records.label.titles.product')  + " : **" + this.translate(product.language_key, true) + "**"), x, y, fontSizeG, fontSizeG);
      }
      y += fontSizeG + 2;
      this.writeBoldAndNormalText(( this.getLoteSafraName(index).name +  ": **" + this.getLoteSafraName(index).record_value) + "**", x, y, fontSizeG, fontSizeG);
      y += fontSizeG + 2;
      if (productObject.netWeight !== '' && productObject.netWeight !== null) {
        this.writeBoldAndNormalText((i18n.t('records.label.titles.netWeight') + ": " + "**" + parseFloat(productObject.netWeight).toFixed(this.decimalFormat) + ' ' + productObject.unit.code + "**"), x, y, fontSizeG, fontSizeG);
      }
      let datas = '';
      if ((this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) || (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null)) {
        y += fontSizeG + 2;
        if (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null) {
          datas =  i18n.t('records.label.titles.expirationDate')  + "  : **" + this.formatDate(this.formData.productsData[index].expirationDate,"DD/MM/YYYY") + "**";
        }
        if (this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) {
          datas = datas + "   "  + i18n.t('records.label.titles.packingDate') +  ": **" + this.formatDate(this.formData.productsData[index].packingDate, "DD/MM/YYYY") + "**";
        }
        this.writeBoldAndNormalText(datas, x, y, fontSizeG, fontSizeG);
      }
    },

    getTopLabelDataGS1v2(x, y, fontSizeG, fontSizeP, index, splitSizeAddress, splitSizeLoc, addressColSpan) {

      const loc1 = this.toStartCase(this.isNotExist(this.formData.productsData[index].form.primary_origin_name))
      const loc2 = this.toStartCase(this.isNotExist(this.formData.productsData[index].form.sdi_origin_name));
      this.writeBoldAndNormalText(("**" + i18n.t('records.fields.origin') + "**" + " : "), x, y, fontSizeG, fontSizeG);
      y += fontSizeG + 2;
      this.doc.text(loc1, (x + 10), y);
      y += fontSizeG + 2;
      this.doc.text(loc2, (x + 10), y);
      y += fontSizeG + 2;

      const text = this.doc.splitTextToSize(this.isNotExist(this.formData.productsData[index].form.allAddressProduced), splitSizeAddress);
      this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.allAddressProduced') + "**" + " :    "), x, y, fontSizeG, fontSizeG);
      this.doc.text(text, (x + addressColSpan), y);
      if (text && text.length > 1) {
        y += fontSizeG + 2;
      }
      if (text && text.length > 2) {
        y += fontSizeG + 2;
      }
      y += fontSizeG + 2;

      if (isValidLatLong(this.formData.productsData[index].form.sdi_origin_lat, true) && isValidLatLong(this.formData.productsData[index].form.sdi_destination_lat, true)) {
        this.writeBoldAndNormalText(("**" + i18n.t('records.label.titles.lat') + "**" + " : " + convertLatitude(this.formData.productsData[index].form.sdi_origin_lat) + '** - **' + "**" + i18n.t('records.label.titles.long') + "**" + " : " + convertLongitude(this.formData.productsData[index].form.sdi_origin_long)), x, y, fontSizeG, fontSizeG);
      }
    },

    getLabelDataGS1v2(x, y, fontSizeG, fontSizeP, index, productObject){
      let product = this.getObjectProduct(index);
      if (product) {
        this.writeBoldAndNormalText(( i18n.t('records.label.titles.product')  + " : **" + this.translate(product.language_key, true) + "**"), x, y, fontSizeG, fontSizeG);
      }
      y += fontSizeG + 2;
      this.writeBoldAndNormalText(( this.getLoteSafraName(index).name +  ": **" + this.getLoteSafraName(index).record_value) + "**", x, y, fontSizeG, fontSizeG);
      y += fontSizeG + 2;
      if (productObject.netWeight !== '' && productObject.netWeight !== null) {
        this.writeBoldAndNormalText((i18n.t('records.label.titles.netWeight') + ": " + "**" + parseFloat(productObject.netWeight).toFixed(this.decimalFormat) + ' ' + productObject.unit.code + "**"), x, y, fontSizeG, fontSizeG);
      }
      let datas = '';
      if ((this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) || (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null)) {
        y += fontSizeG + 2;
        if (this.formData.productsData[index].expirationDate !== '' && this.formData.productsData[index].expirationDate !== null) {
          datas =  i18n.t('records.label.titles.expirationDate')  + "  : **" + this.formatDate(this.formData.productsData[index].expirationDate,"DD/MM/YYYY") + "**";
        }
        if (this.formData.productsData[index].packingDate !== '' && this.formData.productsData[index].packingDate !== null) {
          datas = datas + "   "  + i18n.t('records.label.titles.packingDate') +  ": **" + this.formatDate(this.formData.productsData[index].packingDate, "DD/MM/YYYY") + "**";
        }
        this.writeBoldAndNormalText(datas, x, y, fontSizeG, fontSizeG);
      }
      if (this.formData.productsData[index].additionalInformation !== '' && this.formData.productsData[index].additionalInformation !== null) {
        y += fontSizeG + 2;
        this.writeBoldAndNormalText((this.formData.productsData[index].additionalInformation), x, y, fontSizeP, fontSizeP)
      }
    },

    isNotExist(field) {
      if (field === null)
        return '-'

      return field
    },
    formatDate(date, format){
      return moment(date, "YYYY-MM-DD").format(format)
    },

    writeBoldAndNormalText(inputValue, startX, starty, fontSizeBold, fontSize) {
      // https://codepen.io/AndreKelling/pen/BaoLWao
      // setup config
      const arrayOfNormalAndBoldText = inputValue.split('**');
      arrayOfNormalAndBoldText.map((text, i) => {
        this.doc.setFontSize(fontSizeBold);
        this.doc.setFont(undefined, "normal");
        this.doc.setFontType("bold");
        // every even item is a normal font weight item
        if (i % 2 === 0) {
          this.doc.setFontSize(fontSize);
          this.doc.setFont(undefined, "normal");
          this.doc.setFontType("normal");
        }
        this.doc.text(text, startX, starty);
        startX = startX + this.doc.getStringUnitWidth(text) * fontSize;
      });
    },
    readyView() {
      this.viewReady = true;
    },
    download() {
      this.doc.save('etiqueta.pdf')
    },
    print() {
      this.doc.autoPrint();
      window.open(this.doc.output('bloburl'), '_blank');
    },
    getBarCode128(exclude, index) {
      if (!exclude) {
        exclude = [];
      }
      const product = this.getObjectProduct(index);
      return (
        (exclude.includes('01') || !product ? '' : ('01' + product.gtin)) +
        (exclude.includes('u') || !this.formData.productsData[index].unit || !this.formData.productsData[index].unit.gs1AI ? '' : (this.getGS1AIUnit(this.formData.productsData[index].unit.gs1AI, this.formData.productsData[index].netWeight) + this.getCantGS1(this.formData.productsData[index].netWeight))) +
        (exclude.includes('10') || !this.getLoteSafra(index) ? '' : (('10' + this.getLoteSafra(index))))
      );
    },
    getBarCode128Text(exclude, index) {
      if (!exclude) {
        exclude = [];
      }
      const product = this.getObjectProduct(index);
      return (
        (exclude.includes('01') || !product ? '' : ('(01)' + product.gtin)) +
        (exclude.includes('u') || !this.formData.productsData[index].unit || !this.formData.unit.productsData[index].gs1AI ? '' : '(' + (this.getGS1AIUnit(this.formData.productsData[index].unit.gs1AI, this.formData.productsData[index].netWeight)) + ')' + this.getCantGS1(this.formData.productsData[index].netWeight)) +
        (exclude.includes('10') || !this.getLoteSafra(index) ? '' : (('(10)' + this.getLoteSafra(index))))
      );
    },
    getGS1AIUnit(gs1AI, cant) {
      let cantTotal = cant.split('.');
      if (cantTotal.length > 1) {
        return gs1AI.toString() + cant.split('.')[1].length;
      } else {
        return gs1AI.toString() + 0;
      }
    },
    getCantGS1(cant) {
      let cantTotal = cant.split('.');
      let result = null;
      if (cantTotal.length > 1) {
        result = cantTotal[0] + cantTotal[1];
      } else {
        result = cant;
      }
      return result.toString().padStart(6, '000000')
    },
    getBarCodePLU(index) {
      const product = this.getObjectProduct(index);
      return (product ? product.gtin : '');
    },
    getProduct(index) {
      return this.formData.productsData[index].product_value;
    },
    printCancel() {
      this.$store.commit('records/SET_ORIGINAL_STATE_FOR_PRINT_RECORDS')
      this.$router.push({path: (this.routa)});
    },
    getLoteSafra(index) {
      let lote = null;
      if (this.formData.productsData[index].seasson) {
        lote = this.formData.productsData[index].seasson.id;
      } else {
        lote = this.formData.productsData[index].lot;
      }
      return lote;
    },
    getLoteSafraName(index) {
      let loteResult = {name: '', data: '', record_value: ''};
      loteResult.name = this.translate(this.formData.productsData[index].structureLoteSafra.structure.language_key);
      loteResult.record_value = this.formData.productsData[index].structureLoteSafra.recordStructureValue.record_value[0];
      return loteResult;
    },
    getObjectProduct(index) {
      return this.formData.productsData[index].product;
    },
    // Utils
    setFontConfig(size, color, style, type) {
      if (size) {
        this.doc.setFontSize(size);
      }
      if (color) {
        this.doc.setTextColor(color);
      }
      if (style) {
        this.doc.setFont(undefined, style);
      }
      if (type) {
        this.doc.setFontType(type);
      }
    },
    nextPage() {
      if (this.pageCount >= this.currentPage + 1)
        this.currentPage++;
    },
    backPage() {
      if (this.currentPage > 1)
        this.currentPage--;
    },
    spliter(str, nr) {
      const parts = [];
      for (let i = 0, length = str.length; i < length; i += nr) {
        parts.push(str.substring(i, i + nr));
      }
      return parts;
    },
    actionConfirm(action){
      if (action === 'PRINT') {
        this.print()
      } else if ('DOWNLOAD') {
        this.download()
      }
    },
    async save(action) {
      this.loadingSave = true;
      if(this.formData.save){
        this.labelData = [];
        const currentTime = moment()
        console.log(this.formData)
        _.forEach(this.formData.productsData, row => {
          let labelItem = {};
          labelItem.productDescription = LanguajeService.getKey3(row.product.language_key);
          labelItem.unit = row.unit ? LanguajeService.getKey3(row.unit.languageKey) : '-';
          labelItem.quantity = parseInt(row.netWeight, 10);
          labelItem.lot = row.lot? row.lot: "-";
          labelItem.recordId = row.id;
          labelItem.season = row.seasson?row.seasson.season_name: "-";
          if (row.expirationDate) {
            labelItem.expirationDate = (
              moment(row.expirationDate).format().slice(0, 10) +
              'T' +
              moment(currentTime).format('HH:mm:ss')
            ).slice(0, 19)
          } else {
            labelItem.expirationDate = null
          }
          if (row.packingDate) {
            labelItem.packingDate = (
              moment(row.packingDate).format().slice(0, 10) +
              'T' +
              moment(currentTime).format('HH:mm:ss')
            ).slice(0, 19)
          } else {
            labelItem.packingDate = null
          }
          if (row.expirationDate) {
            labelItem.harvestDate = (
              moment(row.expirationDate).format().slice(0, 10) +
              'T' +
              moment(currentTime).format('HH:mm:ss')
            ).slice(0, 19)
          } else {
            labelItem.harvestDate = null
          }
          labelItem.supplierCode = row.supplierCode ? row.supplierCode : '-'
          labelItem.additionalInformation = row.additionalInformation;
          labelItem.id = null;
          labelItem.code = row.code;
          this.labelData.push(labelItem);
        });
        await this.saveLabel([
          this.labelData,
          this.$toast,
        ]).then(reponse => {
          if(reponse.status === 200){
            this.actionConfirm(action);
          }
        })
      } else {
        this.actionConfirm(action);
      }
      this.loadingSave = false
    },
  },
}
</script>


<style scoped>

</style>
